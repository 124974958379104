<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America BMW Page</h1>
    <Hero
      heading="Power up your BMW with Electrify America"
      headlineLevel="h2"
      subheading="Conquer the roads ahead with the benefits of a BMW charging bundle included with the purchase of your new BMW i vehicle."
    >
      <template slot="image">
        <img src="@/assets/images/BMW/bmw1.png" alt="A BMW Sedan charging at an Electrify America station" />
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Evolve with Electrify America" headlineLevel="h3">
      <p>
        With Electrify America’s convenient, reliable, and growing network of over 4,250 DC-fast chargers from
        coast-to-coast and along major highways and routes, you can enjoy road trips in your BMW i with full peace of
        mind.
      </p>
      <PartnerDropdown id="bmw-select" v-model="modelChosen" :options="bmwDropdownOptions">
        <template #label>
          <div>
            <label for="bmw-select"
              >Select your BMW i model
              <ReferenceLink v-if="modelChosen == null" :num="1">
                Available on eligible new model year BMW vehicles. Charging plan begins upon vehicle purchase.
                Complimentary charging time does not include any idle fees and their associated or applicable taxes
                assessed at the charging station. You will be responsible for paying such fees through your account if
                you incur them during a charging session. There is a 60-minute waiting period between eligible charging
                sessions, and the promotion benefits will not apply to a charging session initiated within this period.
                Non-transferable. Not available for commercial use such as ridesharing. Requires Electrify America
                account, app, enrollment in eligible charging plan, and acceptance of
                <a class="link" :href="'/terms'">Terms of Use</a>. After user-initiated charge session stop or vehicle
                reaches full charge or charging otherwise stops, and 10-minute grace period, idle fees and applicable
                taxes will apply, and user is responsible.
              </ReferenceLink>
            </label>
          </div>
        </template>
      </PartnerDropdown>

      <p>BMW i drivers can focus on the roads and possibilities ahead, leaving gas stations and MPG behind.</p>
      <p v-if="modelChosen == '1'">
        Your <span class="underline">2022 BMW i4 </span>
        <ReferenceLink :num="2">
          Available on new model year 2022 BMW i4 vehicles. Two years of complimentary 30-minute DC fast charging (CCS),
          or 60 minutes of L2 charging sessions, which begins upon vehicle purchase. Non-transferable. Not available for
          commercial use such as ridesharing. Requires Electrify America account, app, enrollment in 2022 BMW i4
          30-minute Charging Plan, and acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>. There is a 60-minute waiting period between eligible
          charging sessions, and the promotion benefits will not apply to a charging session initiated within this
          period. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops,
          and 10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.
        </ReferenceLink>
        comes with 2 years of 30-minute complimentary charging.
      </p>
      <p v-if="modelChosen == '2'">
        Your <span class="underline">2023 BMW i4 </span>
        <ReferenceLink :num="2">
          Available on new model year 2023 BMW i4 vehicles. Two years of included 30-minute DC fast charging (CCS), or
          60 minutes of L2 charging sessions, which begins upon vehicle purchase. Non-transferable. Not available for
          commercial use such as ridesharing. Requires Electrify America account, app, enrollment in 2023 BMW i4
          30-minute Charging plan, and acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>. There is a 60-minute waiting period between eligible
          charging sessions, and the promotion benefits will not apply to a charging session initiated within this
          period. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops,
          and 10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.
        </ReferenceLink>
        comes with 2 years of 30-minute complimentary charging.
      </p>
      <p v-if="modelChosen == '3'">
        Your <span class="underline">2022 BMW iX </span>
        <ReferenceLink :num="2">
          Available on new model year 2022 BMW iX vehicles. Two years of complimentary 30-minute DC fast charging (CCS),
          or 60 minutes of L2 charging sessions, which begins upon vehicle purchase. Non-transferable. Not available for
          commercial use such as ridesharing. Requires Electrify America account, app, enrollment in 2022 BMW iX
          30-minute Charging Plan, and acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>. There is a 60-minute waiting period between eligible
          charging sessions, and the promotion benefits will not apply to a charging session initiated within this
          period. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops,
          and 10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.
        </ReferenceLink>
        comes with 2 years of 30-minute complimentary charging.
      </p>
      <p v-if="modelChosen == '4'">
        Your <span class="underline">2023 BMW iX </span>
        <ReferenceLink :num="2">
          Available on new model year 2023 BMW iX vehicles. Two years of complimentary 30-minute DC fast charging (CCS),
          or 60 minutes of L2 charging sessions, which begins upon vehicle purchase. Non-transferable. Not available for
          commercial use such as ridesharing. Requires Electrify America account, app, enrollment in 2023 BMW iX
          30-minute Charging Plan, and acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>. There is a 60-minute waiting period between eligible
          charging sessions, and the promotion benefits will not apply to a charging session initiated within this
          period. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops,
          and 10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.
        </ReferenceLink>
        comes with 2 years of 30-minute complimentary charging.
      </p>
      <p v-if="modelChosen == '5'">
        Your <span class="underline">2024 BMW iX </span>
        <ReferenceLink :num="2">
          Available on new 2024 BMW iX Electric vehicles purchased from an authorized U.S.-based BMW dealer.
          Complimentary 30 minute DC fast (CCS) charging, and additionally, Level 2 charging over twenty-four (24)
          months begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as ridesharing.
          Requires My BMW app, Electrify America account, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW iX 30-Minute
          Charging plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to cover idle
          fees and taxes; any fees incurred will then be charged to the same method of payment, and an additional
          pre-authorization for $20 will be placed. See <a class="link" :href="'/terms'">Terms of Use</a> for details.
          Complimentary charging time does not include any idle fees. There is a 60-minute waiting period between
          eligible charging sessions, and the promotion benefits will not apply to a charging session initiated within
          this period. Electrify America may discontinue or modify the promotion, or your access to the promotion, in
          its sole discretion. After benefits terminate or expire, your account will convert to a basic Electrify
          America “Pass” account.
        </ReferenceLink>
        comes with 2 years of 30-minute complimentary charging.
      </p>
      <p v-if="modelChosen == '6'">
        Your <span class="underline">2023 BMW i7 </span>
        <ReferenceLink :num="2">
          3 years of charging for the BMW i7 begins upon vehicle purchase. After benefit expires, charging continues at
          prevailing Electrify America Pass pricing. Your BMW Center will help you enroll at time of vehicle handover.
          Offer non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify America
          account creation, enrollment in BMW i7 Charging Plan via the My BMW app, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          Promotion Terms and Conditions and $20 deposit to pre-fund idle fees. Unused idle fee deposit will be refunded
          when account is closed. Electrify America reserves the right to make changes to this program, mandate required
          wait times of 60 minutes between charging sessions, and charge fees for idling beginning 10 minutes after
          charge session stops or vehicle reaches full charge. Users will be notified of program changes via email. In
          the event of suspected fraud or abuse or other unforeseen events, Electrify America may discontinue or modify
          the program in its sole discretion. Frequent and consecutive charging can permanently decrease battery
          capacity, which may not be covered by your vehicle’s warranty.
        </ReferenceLink>
        comes with 3 years of DC fast (CCS) and Level 2 charging.
      </p>
      <p v-if="modelChosen == '7'">
        Your <span class="underline">2024 BMW i4 </span>
        <ReferenceLink :num="2">
          Available on new 2024 BMW i4 Electric vehicles purchased from an authorized U.S.-based BMW dealer.
          Complimentary 30 minute DC fast (CCS) charging, and additionally, Level 2 charging over twenty-four (24)
          months begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as ridesharing.
          Requires My BMW account, app, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW i4 30-Minute
          Charging plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to cover idle
          fees and taxes; any fees incurred will then be charged to the same method of payment, and an additional
          pre-authorization for $20 will be placed. See <a class="link" :href="'/terms'">Terms of Use</a> for details.
          Complimentary charging time does not include any idle fees. There is a 60-minute waiting period between
          eligible charging sessions, and the promotion beneﬁts will not apply to a charging session initiated within
          this period. Electrify America may discontinue or modify the promotion, or your access to the promotion, in
          its sole discretion. After beneﬁts terminate or expire, your account will convert to a basic Electrify America
          “Pass” account. Actual speed of charge may vary.
        </ReferenceLink>
        comes with 2 years of 30-minute complimentary charging.
      </p>
      <p v-if="modelChosen == '8'">
        Your <span class="underline">2024 BMW i7 </span>
        <ReferenceLink :num="2">
          3 years of charging for the 2024 BMW i7 begins upon vehicle purchase. After benefit expires, charging
          continues at prevailing Electrify America Pass pricing. Your BMW Center will help you enroll at time of
          vehicle handover. Offer non-transferable. Not available for commercial use, such as ridesharing. Requires My
          BMW account, app, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW i7 Charging
          plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to cover idle fees and
          taxes; any fees incurred will then be charged to the same method of payment, and an additional
          pre-authorization for $20 will be placed. Complimentary charging time does not include any idle fees. There is
          a 60-minute waiting period between eligible charging sessions, and the promotion beneﬁts will not apply to a
          charging session initiated within this period. Electrify America may discontinue or modify the promotion, or
          your access to the promotion, in its sole discretion. After beneﬁts terminate or expire, your account will
          convert to a basic Electrify America “Pass” account. Actual speed of charge may vary.
        </ReferenceLink>
        comes with 3 years of DC fast (CCS) and Level 2 charging.
      </p>
      <p v-if="modelChosen == '9'">
        Your <span class="underline">2024 BMW i5 </span>
        <ReferenceLink :num="1">
          Available on new 2024 BMW i5 Electric vehicles purchased from an authorized U.S.-based BMW dealer.
          Complimentary 30 minute DC fast (CCS) charging, and additionally, Level 2 charging over twenty-four (24)
          months begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as ridesharing.
          Requires MyBMW account, app, acceptance of Electrify America’s
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW i5 30-Minute
          Charging Plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to cover idle
          fees and taxes; any fees incurred will then be charged to the same method of payment, and an additional
          pre-authorization for $20 will be placed. See Terms of Use for details. Complimentary charging time does not
          include any idle fees. There is a 60-minute waiting period between eligible charging sessions, and the
          promotion benefits will not apply to a charging session initiated within this period. Electrify America may
          discontinue or modify the promotion, or your access to the promotion, in its sole discretion. After benefits
          terminate or expire, your account will convert to a basic Electrify America “Pass” account. Actual speed of
          charge may vary.
        </ReferenceLink>
        comes with 2 years of 30 minute complimentary charging.
      </p>
      <p v-if="modelChosen === '10' || modelChosen === '11' || modelChosen === '12'">
        Your <span class="underline">{{ modelYearAndName }}</span>
        <ReferenceLink :num="1">
          Available on new model year 2025 BMW i4, i5 and iX Electric vehicles purchased from an authorized U.S.-based
          BMW dealer. 1,000 kWh (or, for charging stations where pricing is calculated on a per-minute basis, the
          per-minute equivalent of 1,000 kWh) of complimentary DC Fast or L2 charging begins upon vehicle purchase.
          Non-transferable. Not available for commercial use, such as ridesharing. Requires My BMW account, app,
          acceptance of Electrify America's
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, and enrollment in 2025 BMW i4, i5,
          iX Charging Plan. Complimentary charging does not include any idle fees and their associated or applicable
          taxes assessed at the charging station. A $20 pre-authorization to the method of payment provided upon
          enrollment, is required to begin each charging session to cover idle fees and associated taxes; any fees
          incurred will then be charged to the same method of payment. When the balance exceeds $20, an additional
          pre-authorization for $20 will be placed. See Terms of Use for details. Electrify America may discontinue or
          modify the promotion, or your access to the promotion, in its sole discretion. After benefits are fully
          utilized, terminate, or expire, your account will convert to a basic Electrify America "Pass" account. Actual
          speed of charge may vary.
        </ReferenceLink>
        comes with 1,000 kWh of complimentary charging.
      </p>
      <p v-if="modelChosen === '13'">
        Your <span class="underline">{{ modelYearAndName }}</span>
        <ReferenceLink :num="1">
          Available on new model year 2025 BMW i7 Electric vehicles purchased from an authorized U.S.-based BMW dealer.
          2,000 kWh (or, for charging stations where pricing is calculated on a per-minute basis, the per-minute
          equivalent of 2,000 kWh) of complimentary DC Fast or L2 charging begins upon vehicle purchase.
          Non-transferable. Not available for commercial use, such as ridesharing. Requires My BMW account, app,
          acceptance of Electrify America's
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, and enrollment in 2025 BMW i7
          Charging Plan. Complimentary charging does not include any idle fees and their associated or applicable taxes
          assessed at the charging station. A $20 pre-authorization to the method of payment provided upon enrollment,
          is required to begin each charging session to cover idle fees and associated taxes; any fees incurred will
          then be charged to the same method of payment. When the balance exceeds $20, an additional pre-authorization
          for $20 will be placed. See Terms of Use for details. Electrify America may discontinue or modify the
          promotion, or your access to the promotion, in its sole discretion. After benefits are fully utilized,
          terminate, or expire, your account will convert to a basic Electrify America "Pass" account. Actual speed of
          charge may vary.
        </ReferenceLink>
        comes with 2,000 kWh of complimentary charging.
      </p>
      <p v-if="modelSelectedHasPlugAndCharge">
        Plug&Charge functionality will be available for use with this plan. Plug&Charge can help
        {{ modelName }} owners have a better charging experience by creating a secure, convenient, and effortless way to
        pay for charging sessions on the Electrify America network. Just plug in and we'll handle the rest.
      </p>
      <p v-if="modelChosen">For full program details, <a class="link" :href="clickHereLink">click here</a>.</p>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go With Freedom" headlineLevel="h3">
      <template #image>
        <img
          loading="lazy"
          src="@/assets/images/BMW/bmw2.png"
          alt="A BMW Sedan parked in front of an Electrify America station"
        />
      </template>
      <p v-if="modelChosen == null">
        Get going with Electrify America’s DC ultra-fast charging network. Select your BMW i model above to learn about
        available benefits.
      </p>
      <p v-else-if="modelChosen == '6' || modelChosen == '8'">
        Get going with 3 years of DC fast (CCS) and Level 2 charging on Electrify America’s DC fast charging network
        <ReferenceLink v-if="modelChosen == '6'" :num="5">
          3 years of charging for the BMW i7 begins upon vehicle purchase. After benefit expires, charging continues at
          prevailing Electrify America Pass pricing. Your BMW Center will help you enroll at time of vehicle handover.
          Offer non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify America
          account creation, enrollment in BMW i7 Charging Plan via the My BMW app, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          Promotion Terms and Conditions and $20 deposit to pre-fund idle fees. Unused idle fee deposit will be refunded
          when account is closed. Electrify America reserves the right to make changes to this program, mandate required
          wait times of 60 minutes between charging sessions, and charge fees for idling beginning 10 minutes after
          charge session stops or vehicle reaches full charge. Users will be notified of program changes via email. In
          the event of suspected fraud or abuse or other unforeseen events, Electrify America may discontinue or modify
          the program in its sole discretion. Frequent and consecutive charging can permanently decrease battery
          capacity, which may not be covered by your vehicle’s warranty.
        </ReferenceLink>
        <ReferenceLink v-if="modelChosen == '8'" :num="2">
          3 years of charging for the 2024 BMW i7 begins upon vehicle purchase. After benefit expires, charging
          continues at prevailing Electrify America Pass pricing. Your BMW Center will help you enroll at time of
          vehicle handover. Offer non-transferable. Not available for commercial use, such as ridesharing. Requires My
          BMW account, app, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW i7 Charging
          plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to cover idle fees and
          taxes; any fees incurred will then be charged to the same method of payment, and an additional
          pre-authorization for $20 will be placed. Complimentary charging time does not include any idle fees. There is
          a 60-minute waiting period between eligible charging sessions, and the promotion beneﬁts will not apply to a
          charging session initiated within this period. Electrify America may discontinue or modify the promotion, or
          your access to the promotion, in its sole discretion. After beneﬁts terminate or expire, your account will
          convert to a basic Electrify America “Pass” account. Actual speed of charge may vary. </ReferenceLink
        >. Just follow these simple steps:
      </p>
      <div v-else>
        <p>
          Get going with
          <span v-if="modelSelectedHas1000kWBenefit">1,000 kWh of complimentary</span>
          <span v-else-if="this.modelChosen === '13'">2,000 kWh of complimentary</span>
          <span v-else> 2 years of complimentary 30-minute </span>
          charging on Electrify America’s DC-fast charging network
          <ReferenceLink v-if="modelChosen == '1'" :num="1">
            Available on new model year 2022 BMW i4 vehicles. Two years of complimentary 30-minute DC fast charging
            (CCS), or 60 minutes of L2 charging sessions, which begins upon vehicle purchase. Non-transferable. Not
            available for commercial use such as ridesharing. Requires Electrify America account, app, enrollment in
            2022 BMW i4 30-minute Charging Plan, and acceptance of
            <a class="link" :href="'/terms'">Terms of Use</a>. There is a 60-minute waiting period between eligible
            charging sessions, and the promotion benefits will not apply to a charging session initiated within this
            period. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops,
            and 10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen == '2'" :num="2">
            Available on new model year 2023 BMW i4 vehicles. Two years of included 30-minute DC fast charging (CCS), or
            60 minutes of L2 charging sessions, which begins upon vehicle purchase. Non-transferable. Not available for
            commercial use such as ridesharing. Requires Electrify America account, app, enrollment in 2023 BMW i4
            30-minute Charging plan, and acceptance of
            <a class="link" :href="'/terms'">Terms of Use</a>. There is a 60-minute waiting period between eligible
            charging sessions, and the promotion benefits will not apply to a charging session initiated within this
            period. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops,
            and 10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen == '3'" :num="3">
            Available on new model year 2022 BMW iX vehicles. Two years of complimentary 30-minute DC fast charging
            (CCS), or 60 minutes of L2 charging sessions, which begins upon vehicle purchase. Non-transferable. Not
            available for commercial use such as ridesharing. Requires Electrify America account, app, enrollment in
            2022 BMW iX 30-minute Charging Plan, and acceptance of
            <a class="link" :href="'/terms'">Terms of Use</a>. There is a 60-minute waiting period between eligible
            charging sessions, and the promotion benefits will not apply to a charging session initiated within this
            period. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops,
            and 10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen == '4'" :num="4">
            Available on new model year 2023 BMW iX vehicles. Two years of complimentary 30-minute DC fast charging
            (CCS), or 60 minutes of L2 charging sessions, which begins upon vehicle purchase. Non-transferable. Not
            available for commercial use such as ridesharing. Requires Electrify America account, app, enrollment in
            2023 BMW iX 30-minute Charging Plan, and acceptance of
            <a class="link" :href="'/terms'">Terms of Use</a>. There is a 60-minute waiting period between eligible
            charging sessions, and the promotion benefits will not apply to a charging session initiated within this
            period. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops,
            and 10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen == '5'" :num="4">
            Available on new 2024 BMW iX Electric vehicles purchased from an authorized U.S.-based BMW dealer.
            Complimentary 30 minute DC fast (CCS) charging, and additionally, Level 2 charging over twenty-four (24)
            months begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as
            ridesharing. Requires My BMW app, Electrify America account, acceptance of
            <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
            <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW iX
            30-Minute Charging plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to
            cover idle fees and taxes; any fees incurred will then be charged to the same method of payment, and an
            additional pre-authorization for $20 will be placed. See
            <a class="link" :href="'/terms'">Terms of Use</a> for details. Complimentary charging time does not include
            any idle fees. There is a 60-minute waiting period between eligible charging sessions, and the promotion
            benefits will not apply to a charging session initiated within this period. Electrify America may
            discontinue or modify the promotion, or your access to the promotion, in its sole discretion. After benefits
            terminate or expire, your account will convert to a basic Electrify America “Pass” account.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen == '7'" :num="2">
            Available on new 2024 BMW i4 Electric vehicles purchased from an authorized U.S.-based BMW dealer.
            Complimentary 30 minute DC fast (CCS) charging, and additionally, Level 2 charging over twenty-four (24)
            months begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as
            ridesharing. Requires My BMW account, app, acceptance of
            <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
            <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW i4
            30-Minute Charging plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to
            cover idle fees and taxes; any fees incurred will then be charged to the same method of payment, and an
            additional pre-authorization for $20 will be placed. See
            <a class="link" :href="'/terms'">Terms of Use</a> for details. Complimentary charging time does not include
            any idle fees. There is a 60-minute waiting period between eligible charging sessions, and the promotion
            beneﬁts will not apply to a charging session initiated within this period. Electrify America may discontinue
            or modify the promotion, or your access to the promotion, in its sole discretion. After beneﬁts terminate or
            expire, your account will convert to a basic Electrify America “Pass” account. Actual speed of charge may
            vary.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen == '8'" :num="2">
            3 years of charging for the 2024 BMW i7 begins upon vehicle purchase. After benefit expires, charging
            continues at prevailing Electrify America Pass pricing. Your BMW Center will help you enroll at time of
            vehicle handover. Offer non-transferable. Not available for commercial use, such as ridesharing. Requires My
            BMW account, app, acceptance of
            <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
            <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW i7 Charging
            plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to cover idle fees
            and taxes; any fees incurred will then be charged to the same method of payment, and an additional
            pre-authorization for $20 will be placed. Complimentary charging time does not include any idle fees. There
            is a 60-minute waiting period between eligible charging sessions, and the promotion beneﬁts will not apply
            to a charging session initiated within this period. Electrify America may discontinue or modify the
            promotion, or your access to the promotion, in its sole discretion. After beneﬁts terminate or expire, your
            account will convert to a basic Electrify America “Pass” account. Actual speed of charge may vary.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen == '9'" :num="2">
            Available on new 2024 BMW i5 Electric vehicles purchased from an authorized U.S.-based BMW dealer.
            Complimentary 30 minute DC fast (CCS) charging, and additionally, Level 2 charging over twenty-four (24)
            months begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as
            ridesharing. Requires MyBMW account, app, acceptance of Electrify America’s
            <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
            <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, enrollment in 2024 BMW i5
            30-Minute Charging Plan, and a pre-authorization of $20 to the method of payment provided upon enrollment to
            cover idle fees and taxes; any fees incurred will then be charged to the same method of payment, and an
            additional pre-authorization for $20 will be placed. See Terms of Use for details. Complimentary charging
            time does not include any idle fees. There is a 60-minute waiting period between eligible charging sessions,
            and the promotion benefits will not apply to a charging session initiated within this period. Electrify
            America may discontinue or modify the promotion, or your access to the promotion, in its sole discretion.
            After benefits terminate or expire, your account will convert to a basic Electrify America “Pass” account.
            Actual speed of charge may vary.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen === '10' || modelChosen === '11' || modelChosen === '12'" :num="2">
            Available on new model year 2025 BMW i4, i5 and iX Electric vehicles purchased from an authorized U.S.-based
            BMW dealer. 1,000 kWh (or, for charging stations where pricing is calculated on a per-minute basis, the
            per-minute equivalent of 1,000 kWh) of complimentary DC Fast or L2 charging begins upon vehicle purchase.
            Non-transferable. Not available for commercial use, such as ridesharing. Requires My BMW account, app,
            acceptance of Electrify America's
            <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
            <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, and enrollment in 2025 BMW i4, i5,
            iX Charging Plan. Complimentary charging does not include any idle fees and their associated or applicable
            taxes assessed at the charging station. A $20 pre-authorization to the method of payment provided upon
            enrollment, is required to begin each charging session to cover idle fees and associated taxes; any fees
            incurred will then be charged to the same method of payment. When the balance exceeds $20, an additional
            pre-authorization for $20 will be placed. See Terms of Use for details. Electrify America may discontinue or
            modify the promotion, or your access to the promotion, in its sole discretion. After benefits are fully
            utilized, terminate, or expire, your account will convert to a basic Electrify America "Pass" account.
            Actual speed of charge may vary.
          </ReferenceLink>
          <ReferenceLink v-if="modelChosen === '13'" :num="2">
            Available on new model year 2025 BMW i7 Electric vehicles purchased from an authorized U.S.-based BMW
            dealer. 2,000 kWh (or, for charging stations where pricing is calculated on a per-minute basis, the
            per-minute equivalent of 2,000 kWh) of complimentary DC Fast or L2 charging begins upon vehicle purchase.
            Non-transferable. Not available for commercial use, such as ridesharing. Requires My BMW account, app,
            acceptance of Electrify America's
            <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
            <a class="link" :href="clickHereLink">Promotion Terms and Conditions</a>, and enrollment in 2025 BMW i7
            Charging Plan. Complimentary charging does not include any idle fees and their associated or applicable
            taxes assessed at the charging station. A $20 pre-authorization to the method of payment provided upon
            enrollment, is required to begin each charging session to cover idle fees and associated taxes; any fees
            incurred will then be charged to the same method of payment. When the balance exceeds $20, an additional
            pre-authorization for $20 will be placed. See Terms of Use for details. Electrify America may discontinue or
            modify the promotion, or your access to the promotion, in its sole discretion. After benefits are fully
            utilized, terminate, or expire, your account will convert to a basic Electrify America "Pass" account.
            Actual speed of charge may vary. </ReferenceLink
          >. Just follow these simple steps:
        </p>
      </div>
      <div v-if="modelChosen != null">
        <div>
          <p>
            <strong>Enrollment steps for new customers</strong>
            <span
              v-if="
                modelChosen != '7' &&
                modelChosen != '8' &&
                modelChosen != '9' &&
                modelChosen !== '10' &&
                modelChosen !== '11' &&
                modelChosen !== '12' &&
                modelChosen !== '13'
              "
            >
              <strong>
                (or existing customers that haven’t enrolled yet in the Electrify America & BMW Premium Offer)
              </strong> </span
            >:
          </p>
          <ul>
            <li>
              <strong>Step 1:</strong> Download the My BMW app and map it to your new vehicle.
              <p>
                <small
                  >• Your mobile carrier’s messaging and data fees will apply to your use of the BMW mobile
                  application.</small
                >
              </p>
            </li>
            <li>
              <strong>Step 2:</strong> Click on “View all on Charging” and select “Electrify America” to activate
              charging capability via the My BMW app.
            </li>
            <li>
              <strong>Step 3:</strong> Select “<span v-if="modelChosen == '9' || modelYear === '2025'">Redeem</span
              ><span v-else>Set up</span> a new Premium Offer” and create an Electrify America account.
            </li>
            <li>
              <strong>Step 4:</strong> Select the applicable Premium Offer and enter the enrollment code provided by
              your BMW Center and BMW Welcome Email. Enter your credit card details and confirm.
            </li>
            <li v-if="modelSelectedHasPlugAndCharge">
              <p>To Enable Plug&Charge:</p>
              <strong>Step 5:</strong> In your vehicle, select "Charging" from the Main Menu or Apps and select
              Plug&Charge. <br /><br />
              <strong>Step 6:</strong> Choose the available contract and select “Download and Use”
              <p>
                Success! You can now pull up to a charger, plug in, and start charging using Plug&Charge technology.
                Alternatively, you can always use Click & Charge through the My BMW app, or the NFC card to start
                charging.
              </p>
            </li>
          </ul>
        </div>
        <div
          v-if="
            modelChosen != '7' &&
            modelChosen != '8' &&
            modelChosen != '9' &&
            modelChosen !== '10' &&
            modelChosen !== '11' &&
            modelChosen !== '12' &&
            modelChosen !== '13'
          "
        >
          <p>
            <strong
              >Enrollment steps for customers already enrolled in an Electrify America & BMW Premium Offer:
            </strong>
          </p>
          <ul>
            <li>
              <strong>Step 1:</strong> Download the My BMW app and map it to your eligible BMW.
              <p>
                <small
                  >• Your mobile carrier’s messaging and data fees will apply to your use of the BMW mobile application.
                </small>
              </p>
            </li>
            <li>
              <strong>Step 2:</strong> Click on “View all on Charging” then select “Electrify America” to activate
              charging capability via the My BMW app.
            </li>
            <li>
              <strong>Step 3:</strong> Select “Sign in & transfer Premium Offer”. Sign in using your existing Electrify
              America login.
            </li>
            <li>
              <strong>Step 4:</strong> Select the Premium Offer you’d like to transfer and enter your credit card
              details.
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="
          modelChosen &&
          modelChosen != '8' &&
          modelChosen != '9' &&
          modelChosen !== '10' &&
          modelChosen !== '11' &&
          modelChosen !== '12' &&
          modelChosen !== '13'
        "
        class="app-store-icons"
      >
        <p>
          Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call 1-833-632-2778
          to contact Electrify America Customer Assistance, available 24/7.
        </p>
      </div>

      <div
        v-if="
          modelChosen &&
          modelChosen != '8' &&
          modelChosen != '9' &&
          modelChosen !== '10' &&
          modelChosen !== '11' &&
          modelChosen !== '12' &&
          modelChosen !== '13'
        "
        class="app-store-icons"
      >
        <p><strong>Download the My BMW app:</strong></p>
        <a href="https://apps.apple.com/us/app/my-bmw/id1519457734" rel="noopener" target="_blank"
          ><img loading="lazy" alt="Download on the App Store" src="../../assets/images/ChargingYourEv/AppStore.jpg"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=de.bmw.connected.mobile20.na&gl=us"
          rel="noopener"
          target="_blank"
          ><img loading="lazy" alt="Get it on Google Play" src="../../assets/images/ChargingYourEv/GooglePlay.jpg"
        /></a>
      </div>

      <button v-else-if="!modelChosen" class="button" @click="scrollToId('bmw-select')">SELECT YOUR MODEL</button>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock
      heading="Take Charge with Plug&Charge"
      headlineLevel="h3"
      :noTopMargin="true"
      v-if="modelSelectedHasPlugAndCharge"
    >
      <div>
        <p>
          Plug&Charge can provide a more streamlined charging experience. Please ensure that you are enrolled in your
          {{ chargingPlanName }} Charging Plan.
        </p>
        <ul>
          <li><strong>Step 1:</strong> Drive to any Electrify America DC Fast Charging Station.</li>
          <li><strong>Step 2:</strong> Plug in. Plug the charging connector into your electric vehicle.</li>
          <li>
            <strong>Step 3:</strong> Check the charge. Look at the charging screen to ensure your charge has begun.
          </li>
          <li><strong>Step 4:</strong> Relax. Now that your vehicle is charging, monitor your process in the app.</li>
        </ul>

        <p>
          Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call 1-833-632-2778
          to contact Electrify America Customer Assistance, available 24/7.
        </p>

        <div v-if="modelChosen" class="app-store-icons">
          <p><strong>Download the My BMW app:</strong></p>
          <a href="https://apps.apple.com/us/app/my-bmw/id1519457734" rel="noopener" target="_blank"
            ><img loading="lazy" alt="Download on the App Store" src="../../assets/images/ChargingYourEv/AppStore.jpg"
          /></a>
          <a
            href="https://play.google.com/store/apps/details?id=de.bmw.connected.mobile20.na&gl=us"
            rel="noopener"
            target="_blank"
            ><img loading="lazy" alt="Get it on Google Play" src="../../assets/images/ChargingYourEv/GooglePlay.jpg"
          /></a>
        </div>
      </div>
    </TwoColumnContentBlock>
    <TwoColumnContentBlock heading="" headlineLevel="h3"> </TwoColumnContentBlock>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';
import PartnerDropdown from '@/components/PartnerDropdown/PartnerDropdown.vue';

export default {
  name: 'bmw',
  components: {
    ReferenceLink,
    Hero,
    TwoColumnContentBlock,
    PartnerDropdown,
  },
  metaInfo: {
    title: 'BMW landing page | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/bmw/' }],
  },
  data() {
    return {
      modelChosen: null,
      bmwDropdownOptions: [
        { text: 'Select...', value: null },
        { text: '2025 BMW i7', value: '13' },
        { text: '2025 BMW i5', value: '10' },
        { text: '2025 BMW i4', value: '12' },
        { text: '2025 BMW iX', value: '11' },
        { text: '2024 BMW i7', value: '8' },
        { text: '2024 BMW i5', value: '9' },
        { text: '2024 BMW i4', value: '7' },
        { text: '2024 BMW iX', value: '5' },
        { text: '2023 BMW i7', value: '6' },
        { text: '2023 BMW iX', value: '4' },
        { text: '2023 BMW i4', value: '2' },
        { text: '2022 BMW iX', value: '3' },
        { text: '2022 BMW i4', value: '1' },
      ],
    };
  },
  computed: {
    modelYearAndName() {
      return this.bmwDropdownOptions.find((option) => option.value === this.modelChosen)?.text;
    },
    modelName() {
      const n = this.modelYearAndName.split(' ');
      return n[n.length - 1];
    },
    modelYear() {
      const n = this.modelYearAndName.split(' ');
      return n[0];
    },
    chargingPlanName() {
      if (this.modelYear === '2025' && this.modelChosen !== '13') return '2025 BMW i4, i5, iX';
      return this.modelYearAndName;
    },
    modelSelectedHasPlugAndCharge() {
      return ['8', '9', '10', '11', '12', '13'].includes(this.modelChosen);
    },
    modelSelectedHas1000kWBenefit() {
      return ['10', '11', '12'].includes(this.modelChosen);
    },
    clickHereLink() {
      switch (this.modelChosen) {
        case '1':
          return '/2022-bmw-i4-30-minute-charging-disclosure/';
        case '2':
          return '/2023-bmw-i4-30-minute-charging-disclosure/';
        case '3':
          return '/2022-bmw-ix-30-minute-charging-disclosure/';
        case '4':
          return '/2023-bmw-ix-30-minute-charging-disclosure/';
        case '5':
          return '/2024-bmw-ix-30-minute-charging-disclosure/ ';
        case '6':
          return '/2023-bmw-i7-charging-disclosure/';
        case '7':
          return '/2024-bmw-i4-30-minute-charging-disclosure/';
        case '8':
          return '/2024-bmw-i7-charging-disclosure/';
        case '9':
          return '/2024-bmw-i5-30-minute-charging-disclosure/';
        case '10':
        case '11':
        case '12':
          return '/2025-bmw-i4i5iX-charging-disclosure/';
        case '13':
          return '/2025-bmw-i7-charging-disclosure/';
        default:
          return '/bmw';
      }
    },
  },
  methods: {
    scrollToId(id) {
      document.getElementById(id).scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .hero .hero__image {
  height: auto;
  img {
    object-position: bottom;
    max-height: none;
  }
}
</style>
